






























import mixin from './mixin';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { coccMtdsResource_1_project_singlesBySequenceNbrSummary } from '@/api/projectDetail';
import { SummaryItem } from '@/interface/product';
import { TableCalcStatus } from '@/interface';
import { subtract } from '@/utils';

const columns = [
  {
    key: 'dispNo',
    dataIndex: 'dispNo',
    align: 'center',
    width: 70,
    slots: { title: 'customTitle' },
    customCell: () => {
      return { style: { background: '#F5F7F9' } };
    },
  },
  {
    key: 'projectName',
    title: '名称',
    dataIndex: 'projectName',
    align: 'center',
    scopedSlots: { customRender: 'name' },
  },
  {
    key: 'total',
    title: '合计(元)',
    dataIndex: 'total',
    align: 'center',
  },
  {
    key: 'gfee',
    title: '规费(元)',
    dataIndex: 'gfee',
    align: 'center',
  },
  {
    key: 'safeFee',
    title: '安全生产、文明施工费(元)',
    dataIndex: 'safeFee',
    align: 'center',
  },
];
export default mixin.extend({
  name: 'singleProjectSummary',
  mixins: [mixin],
  data() {
    const summaryData: SummaryItem[] = [];
    return {
      columns,
      summaryData,
    };
  },
  components: {},
  computed: {
    ...mapGetters(['sequenceNbr', 'bidSequenceNbr']),
  },
  watch: {
    bidSequenceNbr: function (val, oldVal) {
      if (val !== oldVal) {
        this.getSingleSummary();
      }
    },
  },
  created() {
    this.getSingleSummary();
  },
  methods: {
    getSingleSummary() {
      const sequenceNbr = this.bidSequenceNbr || this.sequenceNbr;
      if (!sequenceNbr) return '';
      coccMtdsResource_1_project_singlesBySequenceNbrSummary(sequenceNbr).then(
        (res) => {
          if (res.status === 200) {
            this.handleSingleSummary(res.result);
          }
        }
      );
    },
    /**
     * 后台返回数据组装拼接
     */
    handleSingleSummary(data: SummaryItem[]) {
      let list: SummaryItem[] = [];
      data.forEach((item) => {
        const {
          sbf = 0,
          total,
          sortNo,
          projectName,
          safeFee,
          gfee,
          udcUnitProjectModels,
        } = item;
        list.push({
          dispNo: `${sortNo}`,
          projectName: `${projectName}`,
          total: `${
            !total && total !== 0 ? '' : subtract(total as number, sbf)
          }`,
          safeFee: `${!safeFee && safeFee !== 0 ? '' : safeFee}`,
          gfee: `${!gfee && gfee !== 0 ? '' : gfee}`,
        });
        udcUnitProjectModels?.forEach((itemData) => {
          list.push({
            dispNo: `${sortNo}.${itemData.sortNo}`,
            projectName: `${itemData.upName}`,
            total: `${
              !itemData.uptotal && itemData.uptotal !== 0
                ? ''
                : itemData.uptotal
            }`,
            safeFee: `${
              !itemData.safeFee && itemData.safeFee !== 0
                ? ''
                : itemData.safeFee
            }`,
            gfee: `${
              !itemData.gfee && itemData.gfee !== 0 ? '' : itemData.gfee
            }`,
          });
        });
      });
      this.summaryData = list;
      this.updateTableCalc = TableCalcStatus.UPDATE;
    },
  },
});
